import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import IpOrder from '@/layouts/VPS/components/IpOrder';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import OrderConfig from '@/components/Configurator/OrderConfig';
import BaseBasketSummary from '@/components/BaseBasket/BaseBasketSummary';
import qs from 'qs';
import showErrorModal from '@/mixins/showErrorModal';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import addPayment from '@/mixins/billing/addPayment';
export default {
  mixins: [
    billMgrToolActions,
    getLocalizedPeriod,
    showErrorModal,
    wizard,
    handleRedirect,
    updateBalance,
    addPayment,
  ],
  data() {
    return {
      configData: null,
      mp_dataset: {},
      mp_modal: null,
      mp_total: 0,
      isSendingToBasket: false,
      isSendingToPay: false,
      mp_resultSuccess: [],
      mp_resultError: [],
      mp_next: false,
      totalSum: 0,
      startFunc: 'payment.add',
      startParams: {},
      count: 0,
    };
  },
  computed: {
    balance() {
      return this.$store.state.moduleProfile.profile.balance;
    },
    tariff() {
      return this.$store.getters['moduleVPS/GET_CURRENT_VPS'];
    },
    currentId() {
      return this.$store.getters['moduleVPS/GET_CURRENT'];
    },
  },

  watch: {
    total: function (value) {
      this.$emit('change-total', value);
    },
  },
  methods: {
    initModal(name, props = {}) {
      this.$modals.open({
        name,
        onOpen: instance => (this.modal = instance),
        onClose: () => (this.modal = null),
        component: BaseLoader,
        ...props,
      });
    },
    fetchSum(id, payload) {
      const params = {
        plid: id,
        pricelist_id: payload.type,
        count: payload.count,
      };
      return this.fetchBillMgrToolActionWithFunction(params).then(data => {
        this.totalSum = data.cost;
        Vue.set(this.modal.footer.top.props, 'total', data.cost);
        Vue.set(this.modal.footer.top.props, 'amount', data.cost);
      });
    },
    mp_fetchSingle(id) {
      const params = {
        plid: id,
        func: 'service.ip.edit',
      };
      return this.fetchBillMgrAction(params).then(data => {
        const { fields, model, slist } = data;
        this.$store.dispatch('moduleVPS/setIpList', fields).then(() => {
          // console.log('data')
        });
        // if (model && slist && model.period) {
        //   const list = slist.period.map(i => {
        //     return {
        //       ...i,
        //       label: this.getLocalizedPeriod(i.k),
        //     };
        //   });
        //   this.mp_dataset[id] = {
        //     model,
        //     list,
        //     period: model.period,
        //     value: list.find(i => i.k === model.period),
        //   };
        // }
      });
    },
    openModal(title, payload = {}) {
      this.initModal('IpOrder', { title });
      this.fetchBillMgrAction(payload).then(data => {
        if (data.model) this.$store.dispatch('moduleVPS/setModel', data.model).then(() => {});
        const config = new BaseConfigurator({ ...data, customfields: data.fields });
        let formData = {};
        Vue.set(this.modal, 'props', { period: 1, configurator: config });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'footer', {
          top: {
            component: BaseBasketSummary,
            props: { total: this.totalSum, size: 'big', amount: this.fullCost },
            separate: 'top',
          },
          confirm: {
            props: { title: this.$t('pay'), disabled: false, loading: false },
            on: {
              click: () => {
                this.sendToPay(formData.count);
              },
            },
          },
          cancel: {
            on: {
              click: this.$modals.close,
            },
          },
        });
        Vue.set(this.modal, 'on', {
          init: data => {
            formData = data;
            this.fetchSum(payload.plid, formData);
          },
          change: data => {
            formData = data;
            this.count = formData.count;
            this.$emit('change', formData);
            this.fetchSum(payload.plid, formData);
          },
          ['change-total']: value => {
            // console.log(value);
            this.mp_total = value;
          },
          ready: data => {
            formData = data;
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            formData = data;
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
        });
      });
    },
    sendToPay(count) {
      Vue.set(this.modal, 'footer', {
        confirm: {
          props: { title: this.$t('pay'), disabled: false, loading: true },
        },
      });
      const payload = {
        count: count,
        not_for_order: 'on',
        skipbasket: '',
        newface: 'on',
        newbasket: 'on',
        clicked_button: 'basket',
        endoflife: 'off',
        period: '1',
      };
      const params = this.getFormDataParams(payload);
      this.showResFunc.success = false;
      this.isSendingToPay = true;
      this.sendOrder(params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket').then(() => {});
          if (data && data.ok === true) {
            this.isSendingToBasket = false;

            Vue.set(this.modal, 'footer', {
              confirm: {
                props: { title: this.$t('pay'), disabled: false, loading: false },
              },
            });

            this.$store.dispatch('moduleVPS/fetchId', +this.id).then(() => this.$modals.close());
          }
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizardPay()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                this.resetCurrent();
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
                  this.showSuccessModal(text);
                }
                await this.$store.dispatch('moduleVPS/fetchId', +this.id);
                this.updateBalance();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket').then(() => {});
                  });
                }
              });
          }
        })
        .finally(() =>
          setTimeout(() => {
            this.isSendingToPay = false;
          }, 100)
        );
    },
    sendOrder(payload = {}) {
      const params = this.getFormDataParams(payload);
      return this.$store
        .dispatch('moduleVPS/moduleVpsOrder/sendOrder', params)
        .catch(e => this.showError(e));
    },
    getFormDataParams(payload = {}) {
      const formData = this.configData
        ? this.configData
        : this.$store.getters['moduleVPS/getModels'];
      formData.amount = this.totalSum;
      const params = {
        func: 'service.ip.edit',
        ...formData,
        ...payload,
        period: '1',
      };
      return params;
    },
    mp_reset() {
      this.mp_dataset = {};
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
    mp_resetRes() {
      this.mp_resultSuccess = [];
      this.mp_resultError = [];
    },
  },
};
