<!--/* eslint-disable vue/no-v-html */-->
<template>
  <div class="send">
    <!--        :mask="'+9 (999) 999-99-99'"-->
    <div class="l-col">
      <base-input
        size="small"
        :label="$t('title')"
        :value="tariff.domain"
        :required="true"
        use-reactive-validation
        @change="onChange"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import { VpsIpTariff } from '@/models/BillMgr/VpsIpTariff';
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'Ip',
  components: { BaseInput },
  mixins: [setFocus, billMgrToolActions],
  props: {
    tariff: {
      type: VpsIpTariff,
      required: true,
      validator: obj => obj instanceof VpsIpTariff,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
  },
  // created() {
  //   this.fetch();
  // },
  methods: {
    // fetch() {
    //   const params = {
    //     elid: this.tariff.id,
    //     plid: this.id,
    //     func: 'service.ip.edit',
    //   };
    //   this.fetchBillMgrAction(params).then(data => {
    //     console.log(data.model);
    //     // return this.fillWhois(data.model.body);
    //   });
    // },
    // fillWhois(str) {
    //   this.$refs.whois.innerHTML = str.trim();
    // },
    onChange(event) {
      this.$emit('send', event.target.value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Домен"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domain-detail-whois {
  &__content {
  }
}
</style>
